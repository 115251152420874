define("discourse/plugins/DiscordRolesync/discourse/templates/components/discord-bot-status", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    status: {{botstats}} {{current_action}}
  
  */
  {
    "id": "HlO26KGS",
    "block": "[[[1,\"status: \"],[41,[28,[32,0],[\"botstats\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"botstats\"],[[28,[32,1],[\"botstats\"],null]]]]],[[[1,[52,[30,1,[\"botstats\"]],[28,[30,1,[\"botstats\"]],null,null],[28,[32,2],[[30,0],\"botstats\",\"[\\\"The `botstats` property path was used in the `discourse/plugins/DiscordRolesync/discourse/templates/components/discord-bot-status.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.botstats}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\" \"],[41,[28,[32,0],[\"current_action\"],null],[[[8,[39,4],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"current_action\"],[[28,[32,1],[\"current_action\"],null]]]]],[[[1,[52,[30,2,[\"current_action\"]],[28,[30,2,[\"current_action\"]],null,null],[28,[32,2],[[30,0],\"current_action\",\"[\\\"The `current_action` property path was used in the `discourse/plugins/DiscordRolesync/discourse/templates/components/discord-bot-status.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.current_action}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[2]]]],[]]],[1,\"\\n\"]],[\"maybeHelpers\",\"maybeHelpers\"],false,[\"if\",\"botstats\",\"let\",\"hash\",\"current-action\"]]",
    "moduleName": "discourse/plugins/DiscordRolesync/discourse/templates/components/discord-bot-status.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});