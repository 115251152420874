define("discourse/plugins/DiscordRolesync/initializers/discord-rolesync", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeDiscordRolesync(api) {
    // https://github.com/discourse/discourse/blob/master/app/assets/javascripts/discourse/lib/plugin-api.js.es6
    api.modifyClass('model:group', {
      asJSON() {
        let r = this._super();
        r.custom_fields = this.custom_fields;
        return r;
      }
    });
    api.modifyClass('component:groups-form-profile-fields', {
      init() {
        this._super(...arguments);
        if (!this.model.custom_fields) {
          this.model.custom_fields = {};
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "discord-rolesync",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeDiscordRolesync);
    }
  };
});