define("discourse/plugins/DiscordRolesync/discourse/components/discord-bot-status", ["exports", "@ember/runloop", "discourse/lib/ajax", "ember"], function (_exports, _runloop, _ajax, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Component.extend({
    poller: null,
    didInsertElement() {
      (0, _runloop.next)(this, function () {
        this.poller = this.pollBotStats();
      });
    },
    willDestroyElement() {
      (0, _runloop.cancel)(this.poller);
    },
    pollBotStats() {
      return (0, _runloop.later)(this, function () {
        (0, _ajax.ajax)('/discord-rolesync/botstats', {
          type: "GET"
        }).then(function (a) {
          this.set("current_action", a.current_action);
          this.set("botstats", a.botstats);
          this.poller = this.pollBotStats();
        }.bind(this));
      }, 1000);
    }
  });
});