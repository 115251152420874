define("discourse/plugins/DiscordRolesync/discourse/controllers/admin-plugins-sync-discord", ["exports", "discourse/lib/ajax", "discourse-common/lib/get-owner", "discourse-common/utils/decorators", "ember"], function (_exports, _ajax, _getOwner, _decorators, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _ember.default.Controller.extend((_dec = (0, _decorators.default)('botstats'), (_obj = {
    syncDisabled(b) {
      if (b === "online") {
        return false;
      }
      return true;
    },
    actions: {
      syncDiscord() {
        (0, _ajax.ajax)('/discord-rolesync/sync', {
          type: "GET"
        });
      },
      startDiscord() {
        (0, _ajax.ajax)('/discord-rolesync/start', {
          type: "GET"
        });
      },
      stopDiscord() {
        (0, _ajax.ajax)('/discord-rolesync/stop', {
          type: "GET"
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "syncDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "syncDisabled"), _obj)), _obj)));
});