define("discourse/plugins/DiscordRolesync/discourse/templates/admin/plugins-sync-discord", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{discord-bot-status botstats=botstats}}
  <div class='buttons'>
    {{d-button label="sync_discord.sync" action="syncDiscord" disabled=syncDisabled}}
    {{d-button label="sync_discord.start" action="startDiscord" }}
    {{d-button label="sync_discord.stop" action="stopDiscord" }}
  </div>
  
  */
  {
    "id": "TrTeBOd7",
    "block": "[[[1,[28,[35,0],null,[[\"botstats\"],[[30,0,[\"botstats\"]]]]]],[1,\"\\n\"],[10,0],[14,0,\"buttons\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"label\",\"action\",\"disabled\"],[\"sync_discord.sync\",\"syncDiscord\",[30,0,[\"syncDisabled\"]]]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"label\",\"action\"],[\"sync_discord.start\",\"startDiscord\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"label\",\"action\"],[\"sync_discord.stop\",\"stopDiscord\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `botstats` property path was used in the `discourse/plugins/DiscordRolesync/discourse/templates/admin/plugins-sync-discord.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.botstats}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `syncDisabled` property path was used in the `discourse/plugins/DiscordRolesync/discourse/templates/admin/plugins-sync-discord.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.syncDisabled}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"discord-bot-status\",\"d-button\"]]",
    "moduleName": "discourse/plugins/DiscordRolesync/discourse/templates/admin/plugins-sync-discord.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});